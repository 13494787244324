import { makeStyles } from 'tss-react/mui';
import { trackPageView, usePageLoadingTracker } from 'app-insights/appInsightsTracking';
import { navigationStickActions } from 'app/components/GlobalNavigationMenu/NavigationStick.redux';
import { CreatePlacementWrapper } from 'app/components/Placement/CreatePlacement';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getSessionValue, SessionKey, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { EmploymentHistoryGrid } from '../CandidateEmploymentHistoryGrid/EmploymentHistoryGrid';
import { CandidateHeader } from '../CandidateHeader';
import { JobInterestGrid } from '../CandidateJobInterestGrid/JobInterestGrid';
import { JobMatchGrid } from '../CandidateJobMatchGrid/JobMatchGrid';
import {
  ChangeHistoryTab,
  CandidatePlacementsWrapper,
  CommunicationHistoryTab,
  CredentialsTab,
  PersonalInfoTab,
  PreferencesTab,
} from '../CandidateTabPanel';
import { panelActions, PanelChoice, panelReducer, panelSliceKey } from '../PanelOptions/PanelOption.redux';
import { selectPanel } from '../PanelOptions/PanelOption.selectors';
import { QuickGlanceBar } from '../QuickGlanceBar';
import { candidateDetailActions, candidateDetailReducer, candidateDetailSliceKey } from './CandidateDetails.redux';
import { InterviewsTab } from '../CandidateTabPanel/InterviewsTab/InterviewsTab';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { selectTaskStickChosen } from 'app/components/Tasks/store/Tasks.selectors';
import { CandidateHeaderWrapper } from './CandidateHeaderWrapper';
import { useDecision } from '@optimizely/react-sdk';
import { CredentialsGridType } from '../CandidateTabPanel/CredentialsTab/CredentialsGrid';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import {
  breadcrumbSliceKey,
  breadcrumbReducer,
} from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { jobInterestSliceKey, jobInterestReducer } from '../CandidateJobInterestGrid/JobInterest.redux';
import { jobInterestSaga } from '../CandidateJobInterestGrid/JobInterest.saga';
import { jobMatchSliceKey, jobMatchReducer } from '../CandidateJobMatchGrid/JobMatch.redux';
import { jobMatchSaga } from '../CandidateJobMatchGrid/JobMatch.saga';
import { candidateDetailsSaga } from './CandidateDetails.saga';
import {
  candidateJobPreferencesSliceKey,
  jobPreferenceReducer,
  jobPreferncesActions,
} from '../CandidateTabPanel/JobPreferencesTab/store/JobPreferences.redux';
import { jobPreferencesSaga } from '../CandidateTabPanel/JobPreferencesTab/store/JobPreferences.saga';
import { OrderMatchesWrapper } from '../CandidateTabPanel/OrderMatchTab/OrderMatchesGrid/OrderMatchesWrapper';
import { setEntityStickChoosen } from 'app/components/Tasks/TaskSidebar/Transformers';
import {
  candidateOrderMatchReducer,
  candidateOrderMatchSliceKey,
  getCandidateOrderMatchActions,
} from '../CandidateTabPanel/OrderMatchTab/store/candidateOrderMatch.redux';
import {
  selectNavigationProfilesFlag,
  selectNavigationProfilesItems,
  selectNavigationRowId,
} from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.selectors';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import { NavigationProfileCycle } from '@AMIEWEB/Common/NavigationProfileCycle';
import { ff_candidate_ui_preferences, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import { getMultiSelectOptions } from '@AMIEWEB/GlobalSearch/helper';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { ProfileTabLoadable } from '../CandidateTabPanel/ProfileTab/ProfileTabLoadable';
import { DocumentTabLoadable } from '../CandidateTabPanel/DocumentsTab/DocumentsTabLoadable';
import { OrderPreferencesGridLoadable } from '../CandidateTabPanel/JobPreferencesTab/OrderPreferencesLoadable';
import { candidateOrderMatchSaga } from '../CandidateTabPanel/OrderMatchTab/store/candidateOrderMatch.saga';
import { useEnableOneTrustConsents } from 'oidc/CommunicationEnabler';
import { WorkExperienceLoadable } from '../CandidateTabPanel/WorkExperienceTab/WorkExperienceLoadable';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import {
  passportPreCheckPreferenceReducer,
  passportPrecheckPreferencesSliceKey,
} from '../CandidateTabPanel/JobPreferencesTab/PassportPreCheckPreferences/store/PassportPreCheckPreferences.redux';
import { passportPreCheckPreferencesSaga } from '../CandidateTabPanel/JobPreferencesTab/PassportPreCheckPreferences/store/PassportPreCheckPreferences.saga';
import {
  candidateProfileDetailsActions,
  candidateProfileDetailsReducer,
  candidateProfileDetailsSliceKey,
} from 'store/redux-store/candidate-details/slice';
import { candidateProfileDetailsSaga } from 'store/redux-store/candidate-details/saga';
import { SummaryTab } from '../CandidateTabPanel/SummaryTab/SummaryTab';
import { notificationDataActions, notificationDataKey } from 'store/redux-store/notification/notification.redux';
import { notificationSaga } from 'store/redux-store/notification/notification.saga';
import { ConsentType } from 'app/models/Notification/Notification';
import { getActivePlacementsByCandidate } from 'app/services/PlacementServices/PlacementServices';
import { PagingData } from 'app/models/PagingData';

const useStyles = makeStyles()(theme => ({
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export interface RouteParams {
  travelerId: string;
  brandId: string;
}

export const CandidateProfile = () => {
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const params = useParams<RouteParams>();
  useDocumentTitle({ title: `CID ${params.travelerId}` });
  const { resetPageDidLoad, pageDidLoad } = usePageLoadingTracker({ name: 'Candidate Details' }); // Must come after the document title is set
  
  const { t } = useTranslation();
  const [openCreatePlacementForm, setOpenCreatePlacementForm] = useState<boolean>(false);
  const [openCreatePlacementFormX, setOpenCreatePlacementFormX] = useState<boolean>(false);
  const [candidateIdR, setCandidateIdR] = useState<number>(0);
  const [brandIdR, setBrandIdR] = useState<number>(0);
  const [orderIdR, setOrderIdR] = useState<number>(0);
  const [credentialTabSelection, setCredentialTabSelection] = useState<CredentialsGridType | null>(null);
  const [stickChoosen, setStickChoosen] = useState<ITaskStickChosen>({ entity: TaskEntity.CANDIDATES, stick: null });
  useInjectReducer({ key: candidateOrderMatchSliceKey, reducer: candidateOrderMatchReducer });
  useInjectReducer({ key: panelSliceKey, reducer: panelReducer });
  useInjectReducer({ key: candidateDetailSliceKey, reducer: candidateDetailReducer });
  useInjectSaga({ key: candidateDetailSliceKey, saga: candidateDetailsSaga });
  useInjectReducer({ key: breadcrumbSliceKey, reducer: breadcrumbReducer });
  useInjectReducer({ key: jobMatchSliceKey, reducer: jobMatchReducer });
  useInjectReducer({ key: jobInterestSliceKey, reducer: jobInterestReducer });
  useInjectSaga({ key: jobMatchSliceKey, saga: jobMatchSaga });
  useInjectSaga({ key: jobInterestSliceKey, saga: jobInterestSaga });
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectSaga({ key: candidateJobPreferencesSliceKey, saga: jobPreferencesSaga });
  useInjectSaga({ key: candidateOrderMatchSliceKey, saga: candidateOrderMatchSaga });
  useInjectReducer({ key: candidateOrderMatchSliceKey, reducer: candidateOrderMatchReducer });
  useInjectReducer({ key: candidateJobPreferencesSliceKey, reducer: jobPreferenceReducer });
  useInjectReducer({ key: passportPrecheckPreferencesSliceKey, reducer: passportPreCheckPreferenceReducer });
  useInjectSaga({ key: passportPrecheckPreferencesSliceKey, saga: passportPreCheckPreferencesSaga });
  useInjectReducer({ key: candidateProfileDetailsSliceKey, reducer: candidateProfileDetailsReducer });
  useInjectSaga({ key: candidateProfileDetailsSliceKey, saga: candidateProfileDetailsSaga });
  useInjectSaga({ key: notificationDataKey, saga: notificationSaga });
  const history = useHistory();
  const location = useLocation();
  const panelOption = useSelector(selectPanel);
  const taskStickChosen = useSelector(selectTaskStickChosen);
  const navigationProfiles = useSelector(selectNavigationProfilesItems);
  const navigationFlag = useSelector(selectNavigationProfilesFlag);
  const navigationRowId = useSelector(selectNavigationRowId);
  const credentialSubCategoryRef = useRef<number | undefined>(undefined);
  const panel = queryString.parse(window.location.search).panel;

  const [upDisabled, setUpDisabled] = useState<boolean>(false);
  const [downDisabled, setDownDisabled] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
  const [enableJobPreferences] = useDecision(ff_candidate_ui_preferences);
  const [showWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const [preLoadedDivisions, setPreLoadedDivisions] = useState<any>([]);
  const enableOneTrustConsents = useEnableOneTrustConsents();

  const fetchDivisions = async () => {
    const divisionsLookups = await getSearchFilterOptions('divisions');
    const divisionOpts = getMultiSelectOptions(divisionsLookups || []);
    setPreLoadedDivisions(divisionOpts);
  };
  useEffect(() => {
    fetchDivisions();
    trackPageView({ name: `CID ${params.travelerId}` });
    dispatch(getCandidateOrderMatchActions.getLookUps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStickChoosen(taskStickChosen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStickChosen]);

  React.useEffect(() => {
    let currVal;
    let navigationIndex = navigationProfiles.findIndex(s => s.id == navigationRowId);

    currVal = navigationIndex;

    setDownDisabled(navigationIndex === navigationProfiles?.length - 1);
    setUpDisabled(navigationIndex === 0);

    setCurrentIndex(currVal);

    return () => {
      dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: false }));
    };
  }, [params.travelerId, params.brandId]);

  const validatePanelQuery = (): boolean => {
    return panel !== null && panel !== undefined;
  };

  /**
   * Set tab value to professional profile, clear panel options
   * @param value new tab value
   */
  const onNameClick = React.useCallback(
    (value: number, category?: CredentialsGridType, credentialSubCategory?: number) => {
      credentialSubCategoryRef.current = credentialSubCategory;
      dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
      if (queryString.parse(window.location.search)?.tab !== '0') {
        const searchParam = new URLSearchParams({ tab: value.toString() });
        history.push({ pathname: history.location.pathname, search: searchParam.toString() });
      }
      if (category) {
        setCredentialTabSelection(category);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tabs = React.useMemo(() => {
    let candidateTabs = [
      { label: t('candidate.tabLabels.summary'), component: <SummaryTab navigate={onNameClick} /> },
      { label: t('candidate.tabLabels.profile'), component: <ProfileTabLoadable navigate={onNameClick} /> },
      {
        label: t('candidate.tabLabels.credentials'),
        component: (
          <CredentialsTab
            credentialTabSelection={credentialTabSelection}
            credentialSubCategory={credentialSubCategoryRef?.current}
          />
        ),
      },
      { label: t('candidate.tabLabels.documents'), component: <DocumentTabLoadable /> },
      ...(enableJobPreferences?.enabled
        ? [{ label: t('candidate.tabLabels.preferences'), component: <PreferencesTab /> }]
        : []),
      { label: t('candidate.tabLabels.jobPreferences'), component: <OrderPreferencesGridLoadable /> },
      { label: t('candidate.tabLabels.orderMatch'), component: <OrderMatchesWrapper divisions={preLoadedDivisions} /> },
      { label: t('candidate.tabLabels.placements'), component: <CandidatePlacementsWrapper /> },
    { label: t('candidate.tabLabels.personalInfo'), component: <PersonalInfoTab /> },
      { label: t('candidate.tabLabels.interviews'), component: <InterviewsTab navigate={onNameClick} /> },
      //  Hiding notes tab for feature 154997 (Candidate Profile - Hide Notes Tab)
      //  { label: t('candidate.tabLabels.notes'), component: <NotesTab /> },
      { label: t('candidate.tabLabels.changeHistory'), component: <ChangeHistoryTab /> },
      { label: t('candidate.tabLabels.communicationHistory'), component: <CommunicationHistoryTab /> },
    ];

    if (showWorkExperienceTab?.enabled) {
      const workExperienceTab = {
        label: t('candidate.tabLabels.workExperience'),
        component: <WorkExperienceLoadable />,
      };
      candidateTabs.splice(1, 0, workExperienceTab);
    }
    return candidateTabs;
  }, [credentialTabSelection, enableJobPreferences?.enabled, onNameClick, preLoadedDivisions, t]);

  const fetchData = async () => {
    resetPageDidLoad();
    if (isPositiveNumber(params?.travelerId) && isPositiveNumber(params?.brandId)) {
      dispatch(
        candidateProfileDetailsActions.getCandidateDetails({
          candidateId: parseInt(params.travelerId),
          brandId: parseInt(params.brandId),
        }),
      );

      if (enableOneTrustConsents) {
        dispatch(
          notificationDataActions.getCandidatesConsentsAction({
            candidateIds: [params.travelerId],
            brandId: params.brandId,
            consentType: ConsentType.candidateDetails,
          }),
        );

        // Placements: Active placements
        await getActivePlacementsByCandidate(params.travelerId)
          .then(activePlacementsRes => {
            const pagingData: PagingData<PlacementInfo> = activePlacementsRes;
            dispatch(candidateDetailActions.setActivePlacements(pagingData.items));
            dispatch(
              candidateDetailActions.setActivePlacementForCandidate(
                !!(pagingData.items && pagingData.items.length > 0),
              ),
            );
          })
          .catch(err => dispatch(candidateDetailActions.setActivePlacements(null)));
      }

      pageDidLoad();
    }
  };

  useEffect(() => {
    fetchData();
    // new navigation
    dispatch(navigationStickActions.setSelectedSubMenu(undefined));
    dispatch(navigationStickActions.setSelectedMenuByKey('candidate'));
    return () => {
      dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.travelerId, params.brandId]);

  useEffect(() => {
    if (validatePanelQuery()) {
      panel === PanelChoice.JobMatchGrid.toLowerCase() &&
        dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.JobMatchGrid }));
      panel === PanelChoice.JobInterestGrid.toLowerCase() &&
        dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.JobInterestGrid }));
      panel === PanelChoice.EmployementHistoryGrid.toLowerCase() &&
        dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.EmployementHistoryGrid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * On tab change in professional profile
   * @param tab new tab value
   */
  const onTabChanged = (tab: number) => {
    setCredentialTabSelection(null);
    if (tab !== 6) dispatch(jobPreferncesActions.setPreferenceId(undefined));
  };

  useEffect(() => {
    dispatch(taskDetailsActions.setCurrentContainer(TaskEntity.CANDIDATES));
    setEntityStickChoosen(TaskEntity.CANDIDATES, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const refreshStatusData = getSessionValue(SessionKey.createPlacementMFS, StorageType.sessionStorage);
    if (
      refreshStatusData &&
      refreshStatusData.orderId > 0 &&
      refreshStatusData.candidateId > 0 &&
      refreshStatusData.brandId > 0
    ) {
      setCandidateIdR(refreshStatusData.candidateId);
      setBrandIdR(refreshStatusData.brandId);
      setOrderIdR(refreshStatusData.orderId);
      setOpenCreatePlacementFormX(true);
    }
  }, []);

  const handleClickUp = () => {
    const nextIdx = navigationProfiles[currentIndex - 1];
    const nextCandidateId = nextIdx?.candidateId;
    const nextCompanyId = nextIdx?.brandId;
    history.push({
      pathname: `/candidate/${nextCandidateId}/${nextCompanyId}`,
      state: 'navigationCycle',
    });
    dispatch(navigationProfilesAction.setCurrentRowId({ rowId: navigationProfiles[currentIndex - 1]?.id }));
  };

  const handleClickDown = () => {
    const nextIdx = navigationProfiles[currentIndex + 1];
    const nextCandidateId = nextIdx?.candidateId;
    const nextCompanyId = nextIdx?.brandId;
    history.push({
      pathname: `/candidate/${nextCandidateId}/${nextCompanyId}`,
      state: 'navigationCycle',
    });
    dispatch(navigationProfilesAction.setCurrentRowId({ rowId: navigationProfiles[currentIndex + 1]?.id }));
  };

  return (
    <>
      <DetailsPage
        title={t('candidate.title')}
        head={<CandidateHeader onNameClick={onNameClick} />}
        statTiles={<QuickGlanceBar />}
        titleActions={
          <CandidateHeaderWrapper
            setOpenCreatePlacementForm={setOpenCreatePlacementForm}
            stickIconArray={[4]}
            taskStickChosen={stickChoosen}
          />
        }
        navigationActions={
          navigationProfiles?.length > 0 && (navigationFlag || location?.state === 'navigationCycle') ? (
            <NavigationProfileCycle
              handleClickUp={handleClickUp}
              handleClickDown={handleClickDown}
              disabledUp={upDisabled}
              disabledDown={downDisabled}
            />
          ) : null
        }
        currentContainer={TaskEntity.CANDIDATES}
        taskStickChosen={stickChoosen}
      >
        {openCreatePlacementForm && (
          <CreatePlacementWrapper
            isDefaultCandidate
            open
            handleClose={() => setOpenCreatePlacementForm(false)}
            initialArgs={{ candidateId: parseInt(params.travelerId), brandId: parseInt(params.brandId) }}
          />
        )}
        {openCreatePlacementFormX && candidateIdR > 0 && brandIdR > 0 && orderIdR > 0 && (
          <CreatePlacementWrapper
            isDefaultOrder={true}
            isDefaultCandidate={true}
            isOrderRefreshRequest={true}
            isCandidateRefreshRequest={false}
            open
            handleClose={() => setOpenCreatePlacementFormX(false)}
            initialArgs={{ orderId: orderIdR, candidateId: candidateIdR, brandId: brandIdR }}
          />
        )}
        {panelOption.panelOption === PanelChoice.Tabs ? (
          <GenericTabs
            tabs={tabs}
            modifyUrl
            version1
            onTabChanged={onTabChanged}
            analytics={{ name: 'Candidate Profile' }}
          />
        ) : panelOption.panelOption === PanelChoice.JobMatchGrid ? (
          <JobMatchGrid />
        ) : panelOption.panelOption === PanelChoice.JobInterestGrid ? (
          <JobInterestGrid />
        ) : (
          panelOption.panelOption === PanelChoice.EmployementHistoryGrid && <EmploymentHistoryGrid />
        )}
      </DetailsPage>
    </>
  );
};

export default CandidateProfile;
