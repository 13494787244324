/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, CircularProgress, Grid, TextField } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { EditTaskFormBanner } from 'app/components/Common/Banner/EditTaskFormBanner';
import { TaskFormBanner } from 'app/components/Common/Banner/TasksFormBanner';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { CategorizedAutoComplete } from 'app/components/Common/Search/CategorizedAutoComplete';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import {
  CoverageAction,
  ICoverageActionDetails,
  ICoverageData,
  IInternalTask,
  ITask,
  ITaskCategorizedList,
  TaskCategory,
  TaskCoverageActionType,
  TaskCoverageDescription,
  TaskDescription,
  TaskEntity,
  TaskPriority,
  TaskPriorityList,
  ActionType,
} from 'app/models/Tasks/Tasks';
import { addDays, addYears, isBefore, isValid, parse, isWithinInterval } from 'date-fns';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { DateRangePicker } from '../../../../app/ComponentLibrary/DateRangeNew';
import { CCEmployees } from '../CreateTask/CCEmployees';
import { createTaskModalStyles } from '../CreateTask/CreateTask';
import {
  checkIsCCChanged,
  checkIsLatestAssignedUsersChanged,
  convertMultipleAssignedUserData,
  convertUserData,
  fetchplacementEmpData,
  formatTaskCategoryList,
} from '../CreateTask/FormatData';
import { taskDetailsActions } from '../store/Tasks.redux';
import {
  selectedCategoryList,
  selectedUpdatedId,
  selectInternalTaskChangedFlag,
  selectInternalTaskDetailsData,
} from '../store/Tasks.selectors';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useEditTaskNotification } from 'app/components/Notification/Tasks/EditTaskNotificaton';
import { useReadOnly } from 'oidc/userRoles';
import { AssignedToValue } from '../CreateTask/AssignedToEmployeeV2';
import { selectUser } from 'oidc/user.selectors';
import { ff_createexteraltask } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { CoverageConfirmationModal } from '@AMIEWEB/Common/CoverageConfirmation/CoverageConfirmation';
import { checkUserEligible, getAssignee } from '../TaskDetails/helper';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { enGB } from 'date-fns/locale';
import { SelectWithAvatar } from '../CreateTask/SelectWithAvatar';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';
import {
  checkButtonDisableStatusByCoverage,
  checkButtonDisableStatusByCoverageForTaskTile,
} from '../StatusUpdate/helper';
import { getSharedDeskAssociatedUser, isSelectedCoverageOptionAssignee } from './helper';
import { SelectWithIcon } from '../CreateTask/SelectWithIcon';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

export const EditTaskModal = ({
  open,
  handleClose,
  editTaskData,
  container,
  placementDataPresent,
  taskDetail,
}: {
  open: boolean;
  handleClose: () => void;
  editTaskData: any;
  placementDataPresent?: boolean;
  container?: number;
  taskDetail: boolean;
}) => {
  const { t } = useTranslation();
  const [creatExteralTaskFeatureFlag] = useDecision(ff_createexteraltask);
  const { classes } = createTaskModalStyles({ createExtTaskFF: creatExteralTaskFeatureFlag, isExpanded: false });
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const internalTaskDetails = useSelector(selectInternalTaskDetailsData);
  const updatedId = useSelector(selectedUpdatedId);
  const list = useSelector(selectedCategoryList);
  const user = useSelector(selectUser);
  const updateTaskData = useSelector(selectInternalTaskChangedFlag);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'edit-task-submit', delay: 0 });
  const dispatch = useDispatch();
  const [placementEmployees, setPlacementEmployees] = useState<ITypeAheadOption[]>([]);
  const [assignedToValue, setAssignedValue] = useState<ITypeAheadOption[]>();
  const [ccEmpValues, setCCEmpvalues] = useState<ITypeAheadOption[]>([]);
  const [descState, setDescState] = useState('');
  const [taskState, setTaskState] = useState('');
  const [taskCategoryValue, setTaskCategoryValue] = useState<ITaskCategorizedList>();
  const [taskPriority, setTaskPriority] = useState<string>(
    editTaskData.taskPriorityId?.toString() ?? TaskPriority.Normal.toString(),
  );
  const [dueDateState, setDuedateState] = useState<any>();
  const [showField, setShowField] = useState(false);
  const [showccField, setShowccField] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [serverErrorBanner, setServerErrorBanner] = useState(false);
  const [initialState, setInitialState] = useState<any>({});
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [showEditBanner, setShowEditBanner] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState(formatTaskCategoryList(list));
  const [isProvidingCoverage, setIsProvidingCoverage] = useState<boolean>(null);
  const [coverageData, setCoverageData] = useState<ICoverageData>({ openDialog: false });
  const activeCoverage = useSelector(selectActiveCoverage);
  const [selectedCoverageOption, setSelectedCoverageOption] = useState<any>(null);
  const [selectedCoverageId, setSelectedCoverageId] = useState<number>(0);
  const [coverageActionDetails, setCoverageActionDetails] = useState<any>(null);

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;
  const { readOnly } = useReadOnly();
  const date = new Date();
  const startDate = isBefore(initialState?.dueDate, date) ? addDays(initialState?.dueDate, -1) : addDays(date, -1);
  const editTaskVar = {
    task: 'task',
    taskCategory: 'taskCategory',
    cc: 'ccIds',
    dueDate: 'dueDate',
    assignedTo: 'assignedToIds',
    description: 'description',
    onBehalfOf: 'onBehalfOf',
    taskPriorityId: 'taskPriorityId',
  };

  const fetchData = async () => {
    await fetchplacementEmpData(foundPlacement, setPlacementEmployees);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPlacement?.header?.placementId]);

  const sortCategorySelection = value => {
    clearErrors(editTaskVar.task);
    clearErrors(editTaskVar.taskCategory);
    setShowField(false);
    setTaskCategoryValue(value);
    if (!value) return;
    switch (value?.categoryId) {
      case TaskCategory.OTHERS:
        setShowField(true);
        setTaskState('');
        setTimeout(() => {
          setValue(editTaskVar.taskCategory, value?.categoryId);
          setValue(editTaskVar.task, '');
        }, 0);
        break;
      case TaskCategory.MISSINGRFOREQS:
      case TaskCategory.SUBMISSIONREQS:
      case TaskCategory.BOOKINGREQS:
        setTaskState(value?.taskName);
        setTimeout(() => {
          setValue(editTaskVar.task, value?.taskName);
        }, 0);
        break;
    }
  };

  const updateActiveCoverageDataChanges = () => {
    return selectedCoverageOption !== null && isSelectedCoverageOptionAssignee(selectedCoverageOption, taskDetail);
  };
  const validateTaskNameValue = () => {
    return (
      taskState !== initialState?.taskName ||
      dueDateState !== FormatDate(initialState?.dueDate, 'MM/DD/YYYY') ||
      descState !== initialState?.description ||
      !checkIsLatestAssignedUsersChanged(assignedToValue, initialState) ||
      checkIsCCChanged(initialState, ccEmpValues) ||
      taskPriority !== initialState?.taskPriorityId?.toString()
    );
  };

  const validateTaskValue = () => {
    return (
      taskState !== initialState?.task ||
      dueDateState !== FormatDate(initialState?.dueDate, 'MM/DD/YYYY') ||
      descState !== (initialState?.description === null ? '' || null : initialState?.description) ||
      !checkIsLatestAssignedUsersChanged(assignedToValue, initialState) ||
      checkIsCCChanged(initialState, ccEmpValues) ||
      taskPriority !== initialState?.taskPriorityId?.toString()
    );
  };

  useEffect(() => {
    if (showField === false) {
      if (activeCoverage?.length > 0) {
        if (validateTaskNameValue() || updateActiveCoverageDataChanges()) {
          setIsDataChanged(false);
        } else {
          setIsDataChanged(true);
        }
      } else {
        if (validateTaskNameValue()) {
          setIsDataChanged(false);
        } else {
          setIsDataChanged(true);
        }
      }
    } else {
      if (activeCoverage?.length > 0) {
        if (validateTaskValue() || updateActiveCoverageDataChanges()) {
          setIsDataChanged(false);
        } else {
          setIsDataChanged(true);
        }
      } else {
        if (validateTaskValue()) {
          setIsDataChanged(false);
        } else {
          setIsDataChanged(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateTaskData,
    editTaskData,
    dueDateState,
    taskCategoryValue,
    showField,
    taskState,
    descState,
    assignedToValue,
    ccEmpValues,
    selectedCoverageOption,
    taskPriority,
  ]);

  const getChildComponentsValue = () => {
    const ccValues = ccEmpValues?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    const assignedTo = assignedToValue?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    setValue(editTaskVar.assignedTo, assignedTo);
    setValue(editTaskVar.cc, ccValues);
    if (assignedTo) {
      clearErrors(editTaskVar.assignedTo);
    }
  };

  const setDefaultValues = (loadDefault = false) => {
    const defaultTaskData = _cloneDeep(loadDefault ? updateTaskData : editTaskData);
    const intCC = taskDetail ? editTaskData?.cc : editTaskData?.ccValues;
    const convertedAssignedTo = convertMultipleAssignedUserData(editTaskData?.assignedTo);
    const assignedToUsers = convertedAssignedTo?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    const convertedCC = convertUserData(intCC);
    const cc = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    if (cc?.length > 0) {
      setShowccField(true);
    } else {
      setShowccField(false);
    }

    let defaultCategoryValue;
    if (defaultTaskData?.taskCategoryId === TaskCategory.OTHERS) {
      defaultCategoryValue = optionsList?.find(l => l?.categoryId === TaskCategory.OTHERS) || {
        category: 'Category 0',
        categoryId: 0,
        groupBy: 'Others',
        taskId: 0,
        taskName: 'Other',
        title: 'Other',
        id: '0-0',
      };
      setTaskCategoryValue(defaultCategoryValue);
      setShowField(true);
    } else {
      defaultCategoryValue = optionsList?.find(l => l?.taskName === defaultTaskData?.taskName);
      setTaskCategoryValue(defaultCategoryValue);
    }
    setDescState(defaultTaskData?.description);
    setTaskState(defaultTaskData?.task);
    clearErrors('task');
    setAssignedValue(convertedAssignedTo);
    setCCEmpvalues(convertedCC);
    const dueDate = new Date(defaultTaskData?.dueDate);
    dueDate.setHours(23, 59, 59, 999);
    const dt = FormatDate(dueDate, 'MM/DD/YYYY');
    setDuedateState(dt);

    getChildComponentsValue();
    setTimeout(() => {
      setValue(editTaskVar.task, defaultTaskData?.task);
      setValue(editTaskVar.dueDate, dt);
      setValue(editTaskVar.taskCategory, defaultCategoryValue?.categoryId);
      setValue(editTaskVar.description, defaultTaskData?.description);
      setValue(editTaskVar.assignedTo, assignedToUsers);
      setValue(editTaskVar.cc, cc);
      setValue('companyId', defaultTaskData?.task);
    }, 0);

    const initialStateWithData = {
      assignedTo: convertedAssignedTo,
      cc: cc,
      task: defaultTaskData?.task,
      taskName: defaultTaskData?.taskName,
      taskCategory: defaultTaskData?.taskCategory,
      description: defaultTaskData?.description,
      dueDate: dueDate,
      onBehalfOf: null,
      taskPriorityId: defaultTaskData?.taskPriorityId,
    };

    setInitialState(initialStateWithData);
  };

  useEffect(() => {
    setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!updateTaskData) {
      setShowEditBanner(false);
    } else {
      setShowEditBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskData]);

  useEffect(() => {
    if (
      list &&
      ((currentPlacementStatus && currentPlacementStatus?.activityStatusId > 2) ||
        (placementDataPresent && !placementDataPresent) ||
        (container && container !== TaskEntity.PLACEMENTS))
    ) {
      const filteredList =
        list?.filter(
          item =>
            item.categoryId === TaskCategory.OTHERS ||
            item.categoryId === TaskCategory.CREDENTIALING ||
            item.categoryId === TaskCategory.SUBMISSIONREQS ||
            item.categoryId === TaskCategory.BOOKINGREQS,
        ) || [];
      setOptionsList(formatTaskCategoryList(filteredList));
    } else if (list) {
      setOptionsList(formatTaskCategoryList(list));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    getChildComponentsValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccEmpValues, assignedToValue]);

  const onCloseModal = () => {
    if (!isDataChanged) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
      setServerErrorBanner(false);
    }
  };

  const onCloseCall = () => {
    setCancelModalOpen(false);
    setShowField(false);
    setShowccField(false);
    setServerErrorBanner(false);
    setShowEditBanner(false);
    handleClose();
    setCCEmpvalues([]);
    setAssignedValue([]);
    dispatch(taskDetailsActions.setTaskUpdatedId(undefined));
  };

  const checkCoverage = () => {
    const hsgId = 6000;
    const hsgAsRecipient = getAssignee(editTaskData, { employeeId: hsgId });
    const currentUserAsAffiliate = checkUserEligible(editTaskData, user.userInfo);
    if (hsgAsRecipient && !currentUserAsAffiliate) {
      setCoverageData({
        openDialog: true,
        action: CoverageAction.EDIT_TASK,
        coveredUser: hsgAsRecipient,
      });
      return true;
    }
    return false;
  };

  const handleConfirmClick = async () => {
    if (!readOnly) {
      if (showField) {
        await trigger(editTaskVar.task);
      }
      const validTask = await trigger();
      const assignToCheck = getValues(editTaskVar.assignedTo);
      if (assignToCheck?.length <= 0) {
        setError(editTaskVar.assignedTo, {
          type: 'required',
        });
      } else {
        if (validTask) {
          handleSubmit(submitTask)().catch(err => {
            setServerErrorBanner(true);
          });
        }
      }
    }
  };

  const submitTask = formData => {
    const updateTask = _cloneDeep(formData) as IInternalTask;
    const customDateFormat = parse(updateTask.dueDate as string, 'MM/dd/yyyy', new Date(), { locale: enGB });
    customDateFormat.setHours(23, 59, 59);
    updateTask.dueDate = FormatDate(customDateFormat);
    updateTask.task = taskState;
    updateTask.taskId = editTaskData?.taskId;
    updateTask.taskCategory = taskCategoryValue ? taskCategoryValue?.categoryId : TaskCategory.OTHERS;
    updateTask.taskCategoryText = taskCategoryValue ? taskCategoryValue?.groupBy : '';
    updateTask.taskNameId = taskCategoryValue ? taskCategoryValue?.taskId : 0;
    updateTask.assignedToIds = formData.assignedToIds;
    updateTask.taskPriorityId = taskPriority;
    updateTask.ccIds = formData?.ccIds?.map(i => Number(i));
    updateTask.companyId = editTaskData?.companyId;
    updateTask.taskCoverageAction = coverageActionDetails;
    updateTask.sharedDeskAssociatedUser = isNullOrUndefined(user?.userInfo?.sharedProfile)
      ? undefined
      : getSharedDeskAssociatedUser(user?.userInfo, ActionType.EditTask, TaskDescription.EditTask);

    const TaskData: ITask = {
      assignedTo: assignedToValue,
      taskId: editTaskData?.taskId,
      sentBy: coverageActionDetails
        ? coverageActionDetails?.coveredForEmployeeId
        : !isNullOrUndefined(user?.userInfo?.sharedProfile)
        ? parseInt(user?.userInfo?.sharedProfile?.employeeId)
        : user?.userInfo?.employeeId,
      sentDate: updateTask?.sentDate,
      statusId: editTaskData?.statusId,
      placementId: internalTaskDetails?.placementId ?? editTaskData?.placementId,
      orderId: internalTaskDetails?.orderId ?? editTaskData?.orderId,
      facilityId: internalTaskDetails?.facilityId ?? editTaskData?.facilityId,
      companyId: internalTaskDetails?.companyId ?? editTaskData?.companyId,
      unitId: internalTaskDetails?.unitId ?? editTaskData?.unitId,
      facilityName: internalTaskDetails?.facilityName ?? editTaskData?.facilityName,
      statusChangeDate: editTaskData?.statusChangeDate,
      taskCategory: taskCategoryValue ? taskCategoryValue?.categoryId : TaskCategory.OTHERS,
      taskCategoryText: taskCategoryValue ? taskCategoryValue?.groupBy : '',
      task: taskState,
      taskNameId: taskCategoryValue ? taskCategoryValue?.taskId : 0,
      cc: ccEmpValues,
      dueDate: updateTask?.dueDate,
      description: updateTask?.description,
      sentByUser: editTaskData?.createdBy,
      taskCoverageAction: coverageActionDetails,
      taskPriorityId: taskPriority ? parseInt(taskPriority) : TaskPriority.Normal,
      sharedDeskAssociatedUser: isNullOrUndefined(user?.userInfo?.sharedProfile)
        ? undefined
        : getSharedDeskAssociatedUser(user?.userInfo, ActionType.EditTask, TaskDescription.EditTask),
    };
    if (editTaskData?.candidate || internalTaskDetails?.candidate) {
      TaskData.candidateId = internalTaskDetails?.candidate?.id ?? editTaskData?.candidate?.id;
      TaskData.candidateName = internalTaskDetails
        ? `${internalTaskDetails?.candidate?.firstName} ${internalTaskDetails?.candidate?.lastName}`
        : `${editTaskData?.candidate?.firstName} ${editTaskData?.candidate?.lastName}`;
    }
    dispatch(taskDetailsActions.setTaskUpdatedData(TaskData));
    dispatch(taskDetailsActions.editTaskAction({ updateTask, initialTask: initialState }));
  };
  const checkDateValidation = value => {
    if (value.length === 0) {
      return t('required');
    } else if (value.includes('_') || value.includes(' ')) {
      return t('invalidFormat');
    } else {
      const customDateFormat = parse(value, 'MM/dd/yyyy', new Date(), { locale: enGB });
      const isDateValid = isValid(customDateFormat);
      if (isDateValid && isWithinInterval(customDateFormat, { start: startDate, end: addYears(date, 10) })) {
        clearErrors(editTaskVar.dueDate);
        const formattedDate = customDateFormat.setHours(12);
        const dueDate = FormatDate(formattedDate);
        setDuedateState(dueDate);
        return null;
      } else {
        return t('invalidDate');
      }
    }
  };

  useEditTaskNotification();

  useEffect(() => {
    if (updatedId && Number(updatedId) > 0) {
      setServerErrorBanner(false);
      onCloseCall();
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditSuccess'),
          severity: 'success',
        }),
      );
    } else if (updatedId && Number(updatedId) < 0) {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditFailure'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);

  useEffect(() => {
    if (updatedId && Number(updatedId) < 0 && !isCallingAPI) {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskCreationFailure'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallingAPI]);

  const onRefreshClick = () => {
    setDefaultValues(true);
    setShowEditBanner(false);
  };

  useEffect(() => {
    if (isProvidingCoverage !== null) {
      setCoverageData({ ...coverageData, openDialog: false, decision: isProvidingCoverage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProvidingCoverage]);

  useEffect(() => {
    if (coverageData?.decision && coverageData?.openDialog === false) {
      handleConfirmClick();
    } else if (coverageData?.decision === false && coverageData?.openDialog === false) {
      setIsProvidingCoverage(null);
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageData]);

  const checkCoverageActionDisable = () => {
    if (activeCoverage?.length > 0) {
      return taskDetail
        ? checkButtonDisableStatusByCoverage(editTaskData, activeCoverage, selectedCoverageId, user, false)
        : checkButtonDisableStatusByCoverageForTaskTile(editTaskData, activeCoverage, selectedCoverageId, user);
    } else {
      return false;
    }
  };

  const handleCoverageSelection = (data: ICoverageDetails) => {
    setSelectedCoverageOption(data.id);
    setSelectedCoverageId(data?.coveredEmployeeId ?? 0);
    if (data.id.toString() !== user?.userInfo.employeeId.toString()) {
      setCoverageActionDetails({
        coveredByEmployeeId: data?.coveringEmployeeId,
        coveredByEmployeeFirstName: data?.coveringEmployeeFirstName,
        coveredByEmployeeLastName: data?.coveringEmployeeLastName,
        coveredForEmployeeId: data?.coveredEmployeeId,
        coveredForEmployeeFirstName: data?.coveredEmployeeFirstName,
        coveredForEmployeeLastName: data?.coveredEmployeeLastName,
        actionById: user?.userInfo?.employeeId,
        actionDate: moment.tz(new Date(), 'America/Los_Angeles').format(),
        actionType: TaskCoverageActionType.EDIT_TASK,
        actionTypeDescription: TaskCoverageDescription.EDIT_TASK,
      } as ICoverageActionDetails);
    } else {
      setCoverageActionDetails(null);
    }
  };
  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={open}
        dialogTitleProps={{ text: t('notification.createTask.editTitle'), closeButton: true }}
        onClose={onCloseModal}
        variant="blue"
        fullWidth
        className={classes.modalContainer}
        dialogActions={[
          {
            text: t('notification.createTask.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            useDialogOnCloseEvent: true,
            onClick: () => onCloseModal(),
          },
          {
            text: t('notification.createTask.saveBtn'),
            variant: 'contained',
            disabled:
              readOnly || isCallingAPI || isDataChanged || (activeCoverage?.length > 0 && checkCoverageActionDisable()),
            tooltipProps: readOnly
              ? {
                  tooltipContent: t('global.readOnlyTooltip'),
                }
              : undefined,
            onClick: async e => {
              const coverage = checkCoverage();
              if (!coverage) {
                handleConfirmClick();
              }
            },
          },
        ]}
      >
        <Grid container spacing={4} data-testid="create-task-modal-container" style={{ marginTop: '0px' }}>
          {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
          {showEditBanner && (
            <Grid item xs={12} className={classes.banner}>
              <EditTaskFormBanner
                message={t('notification.createTask.editTaskValidation')}
                onClick={e => {
                  onRefreshClick();
                }}
              />
            </Grid>
          )}
          {serverErrorBanner && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.APICallEror')} />
            </Grid>
          )}
          <Grid container spacing={4} item xs={12} sx={{ marginTop: '12px' }} className={classes.banner}>
            {activeCoverage?.length > 0 && (
              <Grid item xs={6}>
                <SelectWithAvatar
                  selectedCoverageOption={selectedCoverageOption}
                  handleCoverageSelection={handleCoverageSelection}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <SelectWithIcon
                name={editTaskVar.taskPriorityId}
                label={t('notification.createTask.priority')}
                options={TaskPriorityList}
                value={taskPriority}
                handleChange={item => {
                  setTaskPriority(item.value);
                }}
                disabled={isCallingAPI}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} data-testid="task-type-input">
            <Controller
              control={control}
              name={showField ? editTaskVar.taskCategory : editTaskVar.task}
              rules={{ required: true }}
              render={({ ref, onChange, ...rest }) => (
                <CategorizedAutoComplete
                  optionsList={optionsList}
                  disabled={isCallingAPI}
                  error={isDirty && (showField ? errors?.taskCategory : errors?.task) ? true : false}
                  className={classes.inputs}
                  classes={{ paper: classes.paper }}
                  labelClass={classes.fieldLabel}
                  id={showField ? editTaskVar.taskCategory : editTaskVar.task}
                  value={taskCategoryValue?.id}
                  label={t('notification.createTask.taskLabel')}
                  groupBy={option => (option.groupBy === 'Others' ? undefined : option.groupBy)}
                  onChange={value => {
                    sortCategorySelection(value);
                    onChange(value?.id);
                  }}
                  noOptionsText={t('notification.createTask.noMatchesFound')}
                  helperText={isDirty && (showField ? errors?.taskCategory : errors?.task) && t('required')}
                />
              )}
            />
          </Grid>
          {showField && (
            <Grid item xs={12} data-testid="task-other-type-input">
              <Controller
                control={control}
                name={editTaskVar.task}
                rules={{
                  required: t('required').toString(),
                  maxLength: { value: 50, message: t('notification.createTask.maxLimitLabel') },
                }}
                render={({ ref, onChange, ...rest }) => (
                  <TextField
                    required
                    variant="filled"
                    color="primary"
                    className={classes.inputs}
                    error={isDirty && errors?.task ? true : false}
                    disabled={isCallingAPI}
                    id="task"
                    label={t('notification.createTask.otherTaskLabel')}
                    InputLabelProps={{
                      className: classes.fieldLabel,
                    }}
                    value={taskState}
                    onChange={e => {
                      if (e.target.value?.length > 50) {
                        setError(editTaskVar.task, {
                          type: 'maxLength',
                          message: t('notification.createTask.maxLimitLabel'),
                        });
                      } else if (e.target.value?.length <= 50) {
                        clearErrors(editTaskVar.task);
                      }
                      onChange(e);
                      setTaskState(e?.target?.value);
                    }}
                    helperText={isDirty && errors?.task && errors?.task?.message}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} data-testid="task-details-input">
            <Controller
              control={control}
              name={editTaskVar.description}
              rules={{ maxLength: { value: 4000, message: t('notification.createTask.maxLimitLabel') } }}
              render={({ ref, onChange, ...rest }) => (
                <TextField
                  multiline
                  variant="filled"
                  className={classes.inputs}
                  color="primary"
                  error={isDirty && errors?.description ? true : false}
                  disabled={isCallingAPI}
                  id="description"
                  label={t('notification.createTask.detailsLabel')}
                  value={descState}
                  maxRows={5}
                  InputLabelProps={{
                    className: classes.fieldLabel,
                  }}
                  onChange={e => {
                    if (e.target.value?.length > 4000) {
                      setError(editTaskVar.description, {
                        type: 'maxLength',
                        message: t('notification.createTask.maxLimitLabel'),
                      });
                    } else if (e.target.value?.length <= 4000) {
                      clearErrors(editTaskVar.description);
                    }
                    onChange(e);
                    setDescState(e.target?.value);
                  }}
                  helperText={isDirty && errors?.description && errors?.description?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={5} data-testid="task-due-date-input" className={classes.dueDateContainer}>
            <Controller
              control={control}
              name={editTaskVar.dueDate}
              defaultValue={dueDateState}
              rules={{ validate: checkDateValidation }}
              render={({ ref, onChange, value, ...rest }) => (
                <DateRangePicker
                  variant="filled"
                  placeholder={t('notification.createTask.dueDateLabel')}
                  className={classes.dueDateInput}
                  initialDateRange={dueDateState}
                  useMaxWidth={true}
                  single={true}
                  minDate={startDate}
                  maxWidth={150}
                  isDisabled={isCallingAPI}
                  labelClass={dueDateState ? classes.fieldLabel : classes.dueDateField}
                  trailingIconShow={true}
                  disablePortal={true}
                  actionBar={false}
                  clickAway={true}
                  keepClickAwayVal={true}
                  value={dueDateState}
                  fallbackPlacements={['bottom-start']}
                  onChange={e => {
                    const dueDate = e?.endDate
                      ? moment.tz(e?.endDate?.setHours(18), 'America/Los_Angeles').format('MM/DD/YYYY')
                      : '';
                    onChange(dueDate);
                    setDuedateState(dueDate);
                  }}
                  onInlineEdit={() => {
                    clearErrors(editTaskVar.dueDate);
                  }}
                  onBlurText={dateString => {
                    onChange(dateString);
                    setDuedateState(dateString);
                  }}
                  error={isDirty && errors?.dueDate ? true : false}
                  helperText={isDirty && errors?.dueDate ? errors?.dueDate?.message : ''}
                  definedRanges={[
                    {
                      label: t('notification.createTask.dueDateLabels.today'),
                      startDate: date,
                      endDate: date,
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.tomorrow'),
                      startDate: addDays(date, 1),
                      endDate: addDays(date, 1),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.2Days'),
                      startDate: addDays(date, 2),
                      endDate: addDays(date, 2),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.7Days'),
                      startDate: addDays(date, 7),
                      endDate: addDays(date, 7),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.30Days'),
                      startDate: addDays(date, 30),
                      endDate: addDays(date, 30),
                      useLabelAsValue: false,
                    },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} data-testid="task-assigned-to-input">
            <Controller
              control={control}
              name={editTaskVar.assignedTo}
              rules={{ required: true }}
              render={({ ref, onChange, value, ...rest }) => (
                <AssignedToValue
                  formMethods={formMethods}
                  taskVar={editTaskVar}
                  placementEmployees={placementEmployees}
                  assignedToValue={assignedToValue}
                  setAssignedValue={data => {
                    setAssignedValue(data);
                    onChange(data);
                  }}
                  ccValues={ccEmpValues}
                  isDisabled={isCallingAPI}
                  error={errors?.assignedToIds}
                  isTouched={isDirty}
                  helperText={errors?.assignedToIds && t('required')}
                  container={container}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {!showccField && (
              <IconButton
                color="primary"
                data-testid="task-cc-btn"
                className={classes.ccBtn}
                disabled={isCallingAPI}
                centerRipple={false}
                onClick={() => {
                  setShowccField(true);
                }}
              >
                {t('notification.createTask.ccBtn')}
              </IconButton>
            )}
            {showccField && (
              <Controller
                control={control}
                name={editTaskVar.cc}
                render={({ ref, onChange, value, ...rest }) => (
                  <CCEmployees
                    placementEmployees={placementEmployees}
                    ccEmpValues={ccEmpValues}
                    setCCEmpvalues={setCCEmpvalues}
                    assignedToValue={assignedToValue}
                    isDisabled={isCallingAPI}
                    formMethods={formMethods}
                    container={container}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </GenericDialog>
      <CoverageConfirmationModal
        openDialog={coverageData?.openDialog}
        handleDecision={setIsProvidingCoverage}
        action={coverageData?.action}
        coveredUser={coverageData?.coveredUser}
      />
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
