import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { placementStatus } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/utils';
import {
  IPlacementEditableRequirementDeps,
  IPlacementEditableRequirements,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import moment from 'moment';
import { isDobAvailable, isSsnAvailable } from './EditRequirementsHelper';

export const SSNRequirementCheck = (ssn, transportation): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly' && (isNullOrUndefined(ssn) || ssn === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const OrderSsnRequirementCheck = (ssn: string | null | undefined): IPlacementEditableRequirementDeps => {
  if (ssn === undefined) return { requirement: requirementType.null, requiredDependency: false };
  return isSsnAvailable(ssn)
    ? { requirement: requirementType.pass, requiredDependency: false }
    : { requirement: requirementType.fail, requiredDependency: false };
};

export const DOBRequirementCheck = (dob, transportation): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly' && (isNullOrUndefined(dob) || dob === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const OrderDOBRequirementCheck = (dob): IPlacementEditableRequirementDeps => {
  if (dob === undefined) return { requirement: requirementType.null, requiredDependency: false };
  return isDobAvailable(dob)
    ? { requirement: requirementType.pass, requiredDependency: false }
    : { requirement: requirementType.fail, requiredDependency: false };
};

export const ArrivalDateCheck = (
  arrivalDate,
  { transportation, placementStatus },
): IPlacementEditableRequirementDeps => {
  const details =
    placementStatus !== 'Traveler Accepts' || transportation === 'Unknown'
      ? { requirement: undefined, requiredDependency: false }
      : (transportation?.toLowerCase() === 'fly' || transportation?.toLowerCase() === 'drive') &&
        (isNullOrUndefined(arrivalDate) || arrivalDate === '')
        ? { requirement: requirementType.fail, requiredDependency: false }
        : { requirement: requirementType.pass, requiredDependency: false };
  return details;
};

export const ArrivalTimeCheck = (arrivalTime, { transportation, placementStatus }):
 IPlacementEditableRequirementDeps => {
  const details = placementStatus !== 'Traveler Accepts' ? { requirement: undefined, requiredDependency: false }
    : transportation?.toLowerCase() === 'drive' && (isNullOrUndefined(arrivalTime) || arrivalTime === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
  return details;
};

export const availableStartDateCheck = (availableStartDate): IPlacementEditableRequirementDeps => {
  const availableMoment = moment(availableStartDate ?? null).startOf('day');
  if (
    !availableMoment.isValid() ||
    (availableMoment.isValid() && availableMoment.isBefore(moment().startOf('day')))
  ) {
    return { requirement: requirementType.fail, requiredDependency: false };
  } else if (availableMoment.isBetween(moment().startOf('day').subtract(1, 'days'), moment().startOf('day').add(14, 'days'), null, '[)')) {
    return { requirement: requirementType.risk, requiredDependency: false };
  } else {
    return { requirement: requirementType.pass, requiredDependency: false };
  }
};

export const extensionTimeOffCheck = (timeOffRequestReason,
  { placementOrderType }): IPlacementEditableRequirementDeps => {
  return placementOrderType === PlacementOrderType.StrikeOrderInHsg
    ? { requirement: undefined, requiredDependency: false }
    : timeOffRequestReason === 3
      ? { requirement: requirementType.risk, requiredDependency: false }
      : { requirement: undefined, requiredDependency: false };
}

export const placementDateCheck = (placementDate): IPlacementEditableRequirementDeps => {
  return placementDate &&
    moment(placementDate?.startDate ?? null).isValid() &&
    moment(placementDate?.endDate ?? null).isValid()
    ? { requirement: requirementType.pass, requiredDependency: false }
    : { requirement: requirementType.fail, requiredDependency: false };
};

export const datesConfirmedCheck = (datesConfirmed): IPlacementEditableRequirementDeps => {
  return datesConfirmed === false || isNullOrUndefined(datesConfirmed)
    ? { requirement: requirementType.fail, requiredDependency: false }
    : { requirement: requirementType.pass, requiredDependency: false };
};

export const dnsClearedCheck = (dnsCleared): IPlacementEditableRequirementDeps => {
  return dnsCleared === false || isNullOrUndefined(dnsCleared)
    ? { requirement: requirementType.risk, requiredDependency: false }
    : { requirement: undefined, requiredDependency: false };
};

export const travelConfirmedCheck = (
  travelConfirmed: IPlacementEditableRequirementDeps,
  dependencies: IPlacementEditableRequirements,
): IPlacementEditableRequirementDeps => {
  if (
    dependencies.datesConfirmed?.requirement === requirementType.fail ||
    dependencies.transportation?.requirement === requirementType.fail ||
    dependencies.gender?.requirement === requirementType.fail || // Transportation data will be undefined if transportation is drive or unknown
    dependencies.travelTimePreference?.requirement === requirementType.fail ||
    dependencies.dob?.requirement === requirementType.fail ||
    dependencies.ssn?.requirement === requirementType.fail ||
    dependencies.departureAirport?.requirement === requirementType.fail ||
    dependencies.departureItineraryLink?.requirement === requirementType.fail ||
    dependencies.placementDate?.requirement === requirementType.fail ||
    dependencies.shiftOffered?.requirement === requirementType.fail
  ) {
    return { requirement: requirementType.fail, requiredDependency: true };
  }
  return travelConfirmed === false || isNullOrUndefined(travelConfirmed)
    ? { requirement: requirementType.fail, requiredDependency: false }
    : { requirement: requirementType.pass, requiredDependency: false };
};

export const GenderCheck = (gender, transportation): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly' && (isNullOrUndefined(gender) || gender === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const TravelTimePreferenceCheck = (travelTimePreference, transportation): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly' &&
      (isNullOrUndefined(travelTimePreference) || travelTimePreference === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const DepartureAirportCheck = (departureAirport, transportation): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly' && (isNullOrUndefined(departureAirport) || departureAirport === '')
      ? { requirement: requirementType.fail, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const TransportationCheck = (transportation): IPlacementEditableRequirementDeps => {
  return isNullOrUndefined(transportation) || transportation === 'Unknown'
    ? { requirement: requirementType.fail, requiredDependency: false }
    : { requirement: requirementType.pass, requiredDependency: false };
};

export const DepartureItineraryLinkCheck = (
  DepartureItineraryLink: requirementType, // Since this is not editable yet, this value comes from API and stored as requirementType in redux
  transportation,
): IPlacementEditableRequirementDeps => {
  return transportation === 'Unknown'
    ? { requirement: undefined, requiredDependency: false }
    : transportation === 'Fly'
      ? { requirement: DepartureItineraryLink, requiredDependency: false }
      : { requirement: requirementType.pass, requiredDependency: false };
};

export const ShiftOfferedCheck = (
  shiftOfferedType: requirementType, // Since this is not editable yet, this value comes from API and stored as requirementType in redux
  placementOrderType,
): IPlacementEditableRequirementDeps => {
  if (placementOrderType === PlacementOrderType.StrikeOrderInHsg)
    return { requirement: shiftOfferedType, requiredDependency: false };
  return { requirement: undefined, requiredDependency: false };
};

export const facilityStatusCheck = (facilityStatus, { placementOrderType }): IPlacementEditableRequirementDeps => {
  return placementOrderType !== PlacementOrderType.Default
    ? { requirement: undefined, requiredDependency: false }
    : facilityStatus === 'Active'
      ? { requirement: requirementType.pass, requiredDependency: false }
      : { requirement: requirementType.fail, requiredDependency: false };
};

export const checkDOBRequirement = (
  currentPlacementStatus: IPlacementStatus,
  placementOrderType: PlacementOrderType,
  orderDOB: any,
  dobValue: string,
) => {
  if (currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.waitingForClearance.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (orderDOB?.type === requirementType.fail || !isDobAvailable(dobValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (orderDOB?.type === requirementType.fail || !isDobAvailable(dobValue)) {
        return true;
      } else {
        return false;
      }
    }
  } else if (
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.sent.toLowerCase() ||
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.interviewScheduled.toLowerCase() ||
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.interviewed.toLowerCase()
  ) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    } else {
      return false;
    }
  } else if (currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.offerByFacility.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    }
  } else if (currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.requestFileOut.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (orderDOB?.type === requirementType.fail || !isDobAvailable(dobValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const checkSsnRequirement = (
  currentPlacementStatus: IPlacementStatus,
  placementOrderType: PlacementOrderType,
  orderSsnType: number | null,
  ssnValue: string,
) => {
  const currentPlacementStatusValue = currentPlacementStatus?.activityStatus?.toLowerCase();
  if (currentPlacementStatusValue === placementStatus.waitingForClearance.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (orderSsnType === requirementType.fail || !isSsnAvailable(ssnValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (orderSsnType === requirementType.fail || !isSsnAvailable(ssnValue)) {
        return true;
      } else {
        return false;
      }
    }
  } else if (
    currentPlacementStatusValue === placementStatus.sent.toLowerCase() ||
    currentPlacementStatusValue === placementStatus.interviewScheduled.toLowerCase() ||
    currentPlacementStatusValue === placementStatus.interviewed.toLowerCase()
  ) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    } else {
      return false;
    }
  } else if (currentPlacementStatusValue === placementStatus.offerByFacility.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    }
  } else if (currentPlacementStatusValue === placementStatus.requestFileOut.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (orderSsnType === requirementType.fail || !isSsnAvailable(ssnValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkVirtualInterviewRequirement = (
  currentPlacementStatus: IPlacementStatus,
  placementOrderType: PlacementOrderType,
  virtualInterviewType: number | null,
) => {
  const currentPlacementStatusValue = currentPlacementStatus?.activityStatus?.toLowerCase();
  if (currentPlacementStatusValue === placementStatus.waitingForClearance.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (virtualInterviewType === requirementType.fail) {
        return true;
      } else {
        return false;
      }
    } else {
      if (virtualInterviewType === requirementType.fail) {
        return true;
      } else {
        return false;
      }
    }
  } else if (
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.sent.toLowerCase() ||
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.interviewScheduled.toLowerCase() ||
    currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.interviewed.toLowerCase()
  ) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    } else {
      return false;
    }
  } else if (currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.offerByFacility.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      return true;
    }
  } else if (currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.requestFileOut.toLowerCase()) {
    if (placementOrderType === PlacementOrderType.Default) {
      if (virtualInterviewType === requirementType.fail) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
